@import url("https://fonts.googleapis.com/css?family=Open+Sans:600&display=swap");
/* line 2, ../sass/general.scss */
body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

/* line 8, ../sass/general.scss */
.inner {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
}

/* line 14, ../sass/general.scss */
.row {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
}

/* line 20, ../sass/general.scss */
.title-section {
  text-align: center;
  font-size: 40px;
  letter-spacing: 2px;
  font-weight: 500;
}

/* line 26, ../sass/general.scss */
.header {
  width: 96%;
  padding: 0 2%;
  background: rgba(6, 32, 72, 0.9);
  position: fixed;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #848488;
}
/* line 35, ../sass/general.scss */
.header ul li {
  list-style: none;
}
/* line 37, ../sass/general.scss */
.header ul li a {
  color: #fff;
}
/* line 42, ../sass/general.scss */
.header .logo {
  float: left;
}
/* line 44, ../sass/general.scss */
.header .logo img {
  width: 100%;
}
/* line 48, ../sass/general.scss */
.header .tools {
  float: right;
}
/* line 50, ../sass/general.scss */
.header .tools ul {
  margin: 0;
}
/* line 53, ../sass/general.scss */
.header .tools ul li a, .header .tools ul li button {
  display: block;
  cursor: pointer;
  background: transparent;
  border: 0;
}
/* line 58, ../sass/general.scss */
.header .tools ul li a .menubars, .header .tools ul li button .menubars {
  background: url("../icon/menu-bars-white.svg") center center/contain no-repeat;
  width: 30px;
  height: 30px;
  display: block;
}
/* line 71, ../sass/general.scss */
.header .menu-expandible ul li {
  list-style: none;
  margin: 0 0 10px;
  transition: all 0.5s ease-in-out;
}
/* line 75, ../sass/general.scss */
.header .menu-expandible ul li a {
  cursor: pointer;
  text-decoration: none;
}

/* line 84, ../sass/general.scss */
.slide-content {
  width: 100%;
  background: #000;
  overflow: hidden;
}
/* line 89, ../sass/general.scss */
.slide-content .slideImages .content {
  margin: 220px auto;
  width: 200px;
}
/* line 92, ../sass/general.scss */
.slide-content .slideImages .content h1 {
  color: #fff;
}
/* line 98, ../sass/general.scss */
.slide-content .carousel .control-arrow {
  opacity: 1 !important;
}

/* line 105, ../sass/general.scss */
.services-content {
  width: 100%;
  margin: 20px 0px 25px;
}
/* line 108, ../sass/general.scss */
.services-content h3 {
  color: #222;
}
/* line 111, ../sass/general.scss */
.services-content .item-service {
  /* box-shadow: 0px 0px 12px 0px #E7EEEE; */
  padding: 7px;
  margin: 0 auto 30px;
}
/* line 116, ../sass/general.scss */
.services-content .item-service .content-item {
  background: #fff;
  height: 94%;
  /* padding-top: 25px; */
}
/* line 120, ../sass/general.scss */
.services-content .item-service .content-item p {
  text-align: center;
  display: block;
  color: #474848;
}
/* line 124, ../sass/general.scss */
.services-content .item-service .content-item p .branded-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  margin: 0 auto;
}
/* line 132, ../sass/general.scss */
.services-content .item-service .content-item p .photo {
  background-image: url("../images/photofactory.png");
}
/* line 135, ../sass/general.scss */
.services-content .item-service .content-item p .info {
  background-image: url("../images/branded.png");
}
/* line 138, ../sass/general.scss */
.services-content .item-service .content-item p .video-drone {
  background-image: url("../images/videodrone.png");
}
/* line 141, ../sass/general.scss */
.services-content .item-service .content-item p .video-factory {
  height: 44px;
  background-image: url("../images/videofactory.png");
}
/* line 145, ../sass/general.scss */
.services-content .item-service .content-item p .web-dev {
  height: 43px;
  width: 50px;
  background-image: url("../images/webdev.png");
}
/* line 152, ../sass/general.scss */
.services-content .item-service .content-item ul li {
  font-size: 12px;
}
/* line 156, ../sass/general.scss */
.services-content .item-service .content-item h4, .services-content .item-service .content-item p {
  margin: 0;
  text-align: center;
}
/* line 160, ../sass/general.scss */
.services-content .item-service .content-item h4 {
  text-transform: uppercase;
  font-weight: 100;
  line-height: 30px;
}
/* line 164, ../sass/general.scss */
.services-content .item-service .content-item h4 .bold-text {
  font-weight: bold;
}
/* line 169, ../sass/general.scss */
.services-content .item-service .content-item .btn-details {
  margin: 25px auto;
  display: block;
  width: 100%;
  max-width: 120px;
  color: #171717;
  text-align: center;
  background: #efefef;
  padding: 10px;
  border: 0;
  text-transform: uppercase;
  text-decoration: none;
}

/* line 187, ../sass/general.scss */
.about-section {
  background-image: url("../images/photofactory.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 96%;
  padding: 2%;
}
/* line 191, ../sass/general.scss */
.about-section .float-text {
  color: #fff;
}

/* line 195, ../sass/general.scss */
.btn-about {
  color: #fff;
  text-align: center;
  background: #2f2f2f;
  border: 0;
  margin-top: 6px;
  border-radius: 20px;
  text-decoration: none;
  display: block;
  font-weight: 400;
}

/* line 207, ../sass/general.scss */
.testimonials-section {
  margin: 100px 0 70px;
}
/* line 209, ../sass/general.scss */
.testimonials-section .text-comment {
  word-wrap: normal;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: #444;
  font-style: italic;
  font-weight: 200;
}
/* line 217, ../sass/general.scss */
.testimonials-section .text-comment .quote {
  color: #fb4c1c;
}
/* line 220, ../sass/general.scss */
.testimonials-section .text-comment h3 {
  width: 100%;
  text-align: center;
  font-size: 15px;
  margin: 20px auto;
  max-width: 260px;
}
/* line 228, ../sass/general.scss */
.testimonials-section .author {
  float: right;
  margin-right: 25px;
  margin-top: 12px;
  position: relative;
}
/* line 233, ../sass/general.scss */
.testimonials-section .author .line-inclined {
  border-left: 1px solid #fb4c1c;
  transform: skewX(-14deg);
  height: 13px;
  position: absolute;
  bottom: 4px;
}
/* line 240, ../sass/general.scss */
.testimonials-section .author span {
  text-align: center;
  padding: 0px 5px;
}

/* line 247, ../sass/general.scss */
.thinking-section {
  background: #f2f2f2;
  width: 96%;
  padding: 2%;
}
/* line 251, ../sass/general.scss */
.thinking-section .float-text {
  color: #000;
}

/* line 256, ../sass/general.scss */
.float-img .image {
  width: 100%;
  margin: 0 auto;
}
/* line 259, ../sass/general.scss */
.float-img .image img {
  width: 100%;
}

/* line 265, ../sass/general.scss */
.services-content .title-section {
  font-style: italic;
  color: #615c62;
  font-weight: 300;
}

.contact-section .title-section {
  font-style: italic;
  color: #615c62;
  font-weight: 300;
}

/* line 271, ../sass/general.scss */
.contact-section .form-contacto .item-input {
  overflow: hidden;
}
/* line 273, ../sass/general.scss */
.contact-section .form-contacto .item-input label {
  font-weight: 200px;
  color: #222;
  margin-left: 6px;
  line-height: 30px;
}
/* line 278, ../sass/general.scss */
.contact-section .form-contacto .item-input label span {
  color: #f00;
}
/* line 282, ../sass/general.scss */
.contact-section .form-contacto .item-input input, .contact-section .form-contacto .item-input textarea, .contact-section .form-contacto .item-input select {
  padding: 7px 5px;
}
/* line 286, ../sass/general.scss */
.contact-section .form-contacto .send-btn {
  padding: 10px;
  width: 150px;
  height: 45px;
  text-transform: uppercase;
  color: #fff;
  background: #223c79;
  border-radius: 6px;
  margin-left: 6px;
}

/* line 299, ../sass/general.scss */
.footer {
  background: #222;
  width: 100%;
  height: 200px;
  position: relative;
}
/* line 304, ../sass/general.scss */
.footer .content-footer {
  position: absolute;
  width: 100%;
  top: 70px;
}
/* line 308, ../sass/general.scss */
.footer .content-footer h3 {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: 200;
  font-size: 15px;
}

@media (min-width: 940px) {
  /* line 1, ../sass/desktop.scss */
  .inner {
    max-width: 1200px;
  }

  /* line 4, ../sass/desktop.scss */
  .col-offset-left {
    margin-left: 16.666666666666664% !important;
  }

  /* line 7, ../sass/desktop.scss */
  .col-offset-right {
    margin-right: 16.66667% !important;
  }

  /* line 11, ../sass/desktop.scss */
  .header .tools {
    display: none;
  }
  /* line 14, ../sass/desktop.scss */
  .header .logo {
    width: 210px;
    margin-top: 10px;
  }

  /* line 20, ../sass/desktop.scss */
  .menu-expandible {
    float: right;
  }
  /* line 22, ../sass/desktop.scss */
  .menu-expandible ul {
    height: 50px;
    padding: 15px;
    margin-top: 16px;
    margin-bottom: 0px;
  }
  /* line 26, ../sass/desktop.scss */
  .menu-expandible ul li {
    display: inline-block;
    margin-right: 6px;
  }
  /* line 29, ../sass/desktop.scss */
  .menu-expandible ul li a {
    text-align: center;
    padding: 6px 17px;
    margin: 0 auto;
    display: block;
  }
  /* line 36, ../sass/desktop.scss */
  .menu-expandible ul .link-item {
    width: 120px;
  }
  /* line 39, ../sass/desktop.scss */
  .menu-expandible ul .contact-link {
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 12px 16px;
  }

  /* line 48, ../sass/desktop.scss */
  .services-content .item-service {
    width: 30%;
    float: left;
    height: 290px;
  }
  /* line 53, ../sass/desktop.scss */
  .services-content .item-service .content-item p {
    font-size: 16px;
    line-height: 20px;
    color: #474848;
    margin: 16px 0 0;
    padding: 0 20px;
  }

  .services-content .item-service .content-item ul li {
    font-size: 16px;
  }

  /* line 64, ../sass/desktop.scss */
  .about-section {
    height: 450px;
    background-attachment: fixed;
  }

  /* line 68, ../sass/desktop.scss */
  .float-text {
    float: right;
    width: 40%;
    margin: 5%;
  }
  /* line 72, ../sass/desktop.scss */
  .float-text h2 {
    font-size: 45px;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 5px;
  }
  /* line 78, ../sass/desktop.scss */
  .float-text h4 {
    font-size: 20px;
  }
  /* line 81, ../sass/desktop.scss */
  .float-text p {
    font-size: 20px;
  }

  /* line 85, ../sass/desktop.scss */
  .btn-about {
    width: 170px;
    padding: 12px 0;
    font-size: 17px;
  }

  /* line 91, ../sass/desktop.scss */
  .testimonials-section .text-comment {
    font-size: 26px;
    max-width: 850px;
  }
  /* line 98, ../sass/desktop.scss */
  .testimonials-section .author span {
    font-size: 15px;
  }

  /* line 103, ../sass/desktop.scss */
  .thinking-section {
    height: 450px;
  }

  /* line 106, ../sass/desktop.scss */
  .float-img {
    float: left;
    width: 50%;
  }
  /* line 109, ../sass/desktop.scss */
  .float-img .image {
    max-width: 300px;
  }

  /* line 114, ../sass/desktop.scss */
  .contact-section {
    margin-bottom: 50px;
  }
  /* line 116, ../sass/desktop.scss */
  .contact-section .form-contacto {
    width: 50%;
    float: left;
  }
  /* line 119, ../sass/desktop.scss */
  .contact-section .form-contacto .item-input {
    width: 50%;
    float: left;
  }
  /* line 122, ../sass/desktop.scss */
  .contact-section .form-contacto .item-input label {
    margin-left: 12px;
  }
  /* line 125, ../sass/desktop.scss */
  .contact-section .form-contacto .item-input input, .contact-section .form-contacto .item-input select, .contact-section .form-contacto .item-input textarea {
    width: 90%;
    background: #f5f5f5;
    margin: 0 auto 20px;
    border-radius: 10px;
    border: 0;
    display: block;
    padding: 11px 5px;
  }
  /* line 135, ../sass/desktop.scss */
  .contact-section .form-contacto select {
    height: 35px;
    width: 92%;
  }
  /* line 139, ../sass/desktop.scss */
  .contact-section .form-contacto .complete-input {
    width: 100%;
    float: none !important;
  }
  /* line 142, ../sass/desktop.scss */
  .contact-section .form-contacto .complete-input input, .contact-section .form-contacto .complete-input textarea {
    width: 95%;
  }
  /* line 146, ../sass/desktop.scss */
  .contact-section .form-contacto .send-btn {
    margin-left: 8px;
  }
  /* line 150, ../sass/desktop.scss */
  .contact-section .map-content {
    width: 50%;
    float: left;
    height: 300px;
  }
  /* line 154, ../sass/desktop.scss */
  .contact-section .map-content iframe {
    height: 300px;
  }
  /* line 157, ../sass/desktop.scss */
  .contact-section .map-content > div {
    height: 480px !important;
  }
}
@media (min-width: 768px) and (max-width: 939px) {
  /* line 1, ../sass/tablet.scss */
  .inner {
    max-width: 500px;
  }

  /* line 6, ../sass/tablet.scss */
  .header .inner {
    min-width: 320px;
    max-width: inherit;
  }
  /* line 10, ../sass/tablet.scss */
  .header .tools {
    display: none;
  }
  /* line 13, ../sass/tablet.scss */
  .header .logo {
    width: 170px;
    margin-top: 15px;
  }
  /* line 17, ../sass/tablet.scss */
  .header .menu-expandible {
    float: right;
  }
  /* line 19, ../sass/tablet.scss */
  .header .menu-expandible ul {
    height: 50px;
    padding: 15px;
  }
  /* line 23, ../sass/tablet.scss */
  .header .menu-expandible ul li {
    display: inline-block;
    margin-right: 6px;
  }
  /* line 26, ../sass/tablet.scss */
  .header .menu-expandible ul li a {
    text-align: center;
    padding: 6px 17px;
    margin: 0 auto;
    display: block;
  }
  /* line 33, ../sass/tablet.scss */
  .header .menu-expandible ul .link-item {
    width: 120px;
  }
  /* line 36, ../sass/tablet.scss */
  .header .menu-expandible ul .contact-link {
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 12px 16px;
  }

  /* line 47, ../sass/tablet.scss */
  .slide-content .carousel .control-arrow {
    z-index: 1 !important;
  }

  /* line 54, ../sass/tablet.scss */
  .services-content .item-service {
    width: 100%;
    max-width: 300px;
    height: 250px;
  }
  /* line 59, ../sass/tablet.scss */
  .services-content .item-service .content-item p {
    font-size: 13px;
    line-height: 20px;
    padding: 0 20px;
  }

  /* line 68, ../sass/tablet.scss */
  .float-text {
    padding: 30% 5% 5%;
    max-width: 500px;
    margin: 0 auto;
    width: 90%;
  }
  /* line 73, ../sass/tablet.scss */
  .float-text h2 {
    text-transform: uppercase;
    font-weight: 200;
    font-size: 30px;
    line-height: 0px;
  }
  /* line 79, ../sass/tablet.scss */
  .float-text h4 {
    line-height: 15px;
  }

  /* line 83, ../sass/tablet.scss */
  .btn-about {
    width: 120px;
    padding: 7px 0;
    font-size: 13px;
  }

  /* line 90, ../sass/tablet.scss */
  .testimonials-section .text-comment {
    font-size: 20px;
  }
  /* line 96, ../sass/tablet.scss */
  .testimonials-section .author .line-inclined {
    height: 9px;
  }
  /* line 99, ../sass/tablet.scss */
  .testimonials-section .author span {
    font-size: 10px;
  }

  /* line 106, ../sass/tablet.scss */
  .thinking-section .float-text {
    padding: 5% 5% 5%;
  }

  /* line 111, ../sass/tablet.scss */
  .float-img .image {
    max-width: 180px;
  }

  /* line 116, ../sass/tablet.scss */
  .contact-section .title-section {
    color: #444;
    text-transform: uppercase;
    font-weight: 300;
  }
  /* line 121, ../sass/tablet.scss */
  .contact-section .form-contacto {
    padding: 1%;
  }
  /* line 124, ../sass/tablet.scss */
  .contact-section .form-contacto .item-input input, .contact-section .form-contacto .item-input textarea, .contact-section .form-contacto .item-input select {
    width: 95%;
    background: #f5f5f5;
    margin: 0 auto 10px;
    border-radius: 10px;
    border: 0;
    display: block;
  }
  /* line 132, ../sass/tablet.scss */
  .contact-section .form-contacto .item-input select {
    width: 98%;
    background: #f5f5f5 url("https://www.freeiconspng.com/uploads/arrow-up-icon-23.png") no-repeat;
    background-size: 15px;
    background-position: right 10px center;
    padding-left: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  /* line 146, ../sass/tablet.scss */
  .map-content {
    width: 100%;
    height: 480px;
  }
  /* line 149, ../sass/tablet.scss */
  .map-content iframe {
    height: 480px;
  }
  /* line 152, ../sass/tablet.scss */
  .map-content > div {
    height: 480px !important;
  }
}
@media (max-width: 767px) {
  /* line 4, ../sass/mobile.scss */
  .title-section {
    font-size: 32px;
    line-height: 50px;
  }

  /* line 9, ../sass/mobile.scss */
  .header {
    height: 70px;
  }
  /* line 12, ../sass/mobile.scss */
  .header ul li {
    list-style: none;
  }
  /* line 16, ../sass/mobile.scss */
  .header .logo {
    float: left;
    width: 105px;
    margin: 15px 0;
  }
  /* line 21, ../sass/mobile.scss */
  .header .tools {
    float: right;
    display: block;
  }
  /* line 25, ../sass/mobile.scss */
  .header .tools ul li {
    padding: 15px;
  }
  /* line 30, ../sass/mobile.scss */
  .header .menu-expandible {
    width: 100%;
    position: fixed;
    background: rgba(6, 32, 72, 0.9);
    top: 71px;
    left: 0;
    height: 100%;
    z-index: 3;
    transition: all 0.5s ease-in-out;
  }
  /* line 39, ../sass/mobile.scss */
  .header .menu-expandible ul {
    padding: 0;
  }
  /* line 41, ../sass/mobile.scss */
  .header .menu-expandible ul li {
    padding: 10px;
    text-align: center;
  }
  /* line 48, ../sass/mobile.scss */
  .header .menu-expandible ul li:hover {
    background: #ccc;
  }
  /* line 53, ../sass/mobile.scss */
  .header .leftMenu {
    left: -100%;
  }

  /* line 60, ../sass/mobile.scss */
  .slide-content .carousel .control-arrow {
    z-index: 1 !important;
  }

  /* line 67, ../sass/mobile.scss */
  .services-content .item-service {
    width: 100%;
    max-width: 300px;
    height: 270px;
  }
  /* line 72, ../sass/mobile.scss */
  .services-content .item-service .content-item p {
    font-size: 13px;
    line-height: 20px;
    margin: 6px auto;
  }

  /* line 81, ../sass/mobile.scss */
  .about-section {
    background: url("../images/photofactoryMobile.png") center center no-repeat/cover;
  }

  /* line 85, ../sass/mobile.scss */
  .float-text {
    padding: 63% 5% 5%;
    max-width: 350px;
    margin: 0 auto;
    width: 90%;
  }
  /* line 90, ../sass/mobile.scss */
  .float-text h2 {
    text-transform: uppercase;
    font-weight: 200;
    font-size: 30px;
    line-height: 0px;
  }
  /* line 96, ../sass/mobile.scss */
  .float-text h4 {
    line-height: 15px;
  }

  /* line 100, ../sass/mobile.scss */
  .btn-about {
    width: 120px;
    padding: 7px 0;
    font-size: 13px;
  }

  /* line 107, ../sass/mobile.scss */
  .testimonials-section .text-comment {
    max-width: 380px;
    font-size: 20px;
  }
  /* line 114, ../sass/mobile.scss */
  .testimonials-section .author .line-inclined {
    height: 9px;
  }
  /* line 117, ../sass/mobile.scss */
  .testimonials-section .author span {
    font-size: 10px;
  }

  /* line 124, ../sass/mobile.scss */
  .thinking-section .float-text {
    padding: 5% 5% 5%;
  }

  /* line 129, ../sass/mobile.scss */
  .float-img .image {
    max-width: 180px;
  }

  /* line 134, ../sass/mobile.scss */
  .contact-section .title-section {
    color: #444;
    text-transform: uppercase;
    font-weight: 300;
    font-style: italic;
  }
  /* line 140, ../sass/mobile.scss */
  .contact-section .form-contacto {
    padding: 1%;
    margin-bottom: 15px;
  }
  /* line 144, ../sass/mobile.scss */
  .contact-section .form-contacto .item-input input, .contact-section .form-contacto .item-input textarea, .contact-section .form-contacto .item-input select {
    width: 95%;
    background: #f5f5f5;
    margin: 0 auto 10px;
    border-radius: 10px;
    border: 0;
    display: block;
  }
  /* line 152, ../sass/mobile.scss */
  .contact-section .form-contacto .item-input select {
    width: 98%;
    background: #f5f5f5 url("https://www.freeiconspng.com/uploads/arrow-up-icon-23.png") no-repeat;
    background-size: 15px;
    background-position: right 10px center;
    padding-left: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  /* line 166, ../sass/mobile.scss */
  .map-content {
    width: 100%;
    height: 480px;
  }
  /* line 169, ../sass/mobile.scss */
  .map-content iframe {
    height: 480px;
  }
  /* line 172, ../sass/mobile.scss */
  .map-content > div {
    height: 480px !important;
  }

}
